<script setup>
import Layout from "../../layouts/main.vue";
import { onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../service/MethodService";
import DataForm from "./dataFormNhomQuyen";
import mushroom from "cem-primary-api";
import Swal from "sweetalert2";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
import { Filter } from "mushroomjs";
import { profileUserStore } from "@/stores/profileUser";


const profileUser = profileUserStore();
const { t } = useI18n();
let loading = ref(false); // loading -> đổi thành true khi có api
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const dataTables = reactive({ value: [] }); // data bảng
const pageSizeDefault = ref(20);
const rolesAccount = ref("");


const fn_handle = async (type, scope, row) => {
  if (type === "update") {
    router.push({
      name: "CapNhatNhomQuyen",
      params: { id: row.id },
    });
  }
  if (type === "delete") {
    Swal.fire({
      title: t("t_warning"),
      text: t("t_record_delete"),
      icon: "warning",
      confirmButtonColor: "#f46a6a",
      confirmButtonText: t("swal_accept"),
      cancelButtonText: t("swal_cancel"),
      showCancelButton: true,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const responseGroup = await mushroom.group.deleteAsync({
            id: row.id, // required
          });
          if (responseGroup.result) {
            toastr.success(t("toastr_delete_group_successfully"));
            getListGroup();
          }
        } catch (e) {
          console.error("Có lỗi: %o", e.message);
          if (e.message === "Group is not empty") {
            toastr.error(t("toastr_delete_group_is_used"));
          } else MethodService.showError(e.code);
        }
      }
    });
  }
};
// hiển thị form tìm kiếm
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
// tìm kiếm
const fn_TimKiem = () => {
  tableRules.dataSearch.value["name"]
    ? (tableRules.dataSearch.value["name"] =
        tableRules.dataSearch.value["name"].trim())
    : (tableRules.dataSearch.value["name"] = "");

  tableRules.dataSearch.value["net_xs"]
    ? (tableRules.dataSearch.value["net_xs"] =
        tableRules.dataSearch.value["net_xs"].trim())
    : (tableRules.dataSearch.value["net_xs"] = "");

  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  tableRules.offset = 0;
  tableRules.sort = "";
  console.log(tableRules.filters);
  getListGroup();
};
// phân trang
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  getListGroup();
};
const fn_tableSortChange = (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  getListGroup();
};

const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query; // không phải router
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};

// chuyển sang màn thêm mới tài khoản
const fn_ThemMoiNhomQuyen = () => {
  router.push({ name: "ThemMoiNhomQuyen" });
};

// đổi lại func lấy danh sách quyền hạn
const getListGroup = async () => {
  loading.value = true;
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .replace({
      name: "NhomQuyen",
      query: {
        limit: tableRules.limit,
        page: tableRules.page,
        sort: tableRules.sort,
        filters: tableRules.filters,
        showFormSearch: tableRules.showFormSearch,
      },
    })
    .catch(() => {});
  // kiểm tra tài khoản nếu có roles là User
  // lấy group_id theo tài khoản để truyền vào filter
  if (rolesAccount.value === "User") {
    try {
      const response = await mushroom.user_group.listAsync();
      dataFilter.filters = Filter.eq("id", response.result[0].group_id).build();
    } catch (e) {
      console.error("Có lỗi: %o", e);
    }
  }
  try {
    const response = await mushroom.group.listAsync(dataFilter);
    if (response.result) {
      let res = await changeData(response.result);
      dataTables.value = res;
      tableRules.total = response.meta.total;
      tableRules.page = response.meta.offset / response.meta.limit + 1;
    }
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};
const changeData = async (data) => {
  data.forEach((element) => {
    element.nameProvince = ref("");
    element.netX = ref("");
    element.toolTipContent = ref("");
    let arrForPush = [];
    element.province_ids.forEach(async (item) => {
      try {
        const response = await mushroom.province.listAsync({
          filters: Filter.eq("id", item).build(),
        });
        if (response.result[0]) arrForPush.push(response.result[0].code3);
        let trimDSTinh = "";
        let arrForPushLeng = arrForPush.join(", ");
        element.toolTipContent = arrForPush.join(", ");
        if (arrForPushLeng.length > 18) {
          trimDSTinh = arrForPushLeng.slice(0, 18) + " ...";
        } else trimDSTinh = arrForPushLeng;
        if (arrForPush.length === 0) {
          element.nameProvince.value = "";
        } else
          element.nameProvince.value =
            arrForPush.length + " tỉnh " + "(" + trimDSTinh + ")";
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    });

    element.netX.value = element.net_xs.join(", ");
    if (element.roles) {
      if (element.roles.includes("Edit")) {
        element.roles = "Edit";
      } else element.roles = "View";
    }
  });
  return data;
};
onMounted(async () => {
  rolesAccount.value = profileUser.roles;
  fn_hashUrl();
  getListGroup();
  setTimeout(() => {
    let activeMenu = document.querySelector("#dvhc");
    activeMenu.classList.add("active");
  }, 500);
});
</script>

<template>
  <Layout>
    <div class="card" style="min-height: 80vh">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-list-group") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_ThemMoiNhomQuyen"
              v-if="rolesAccount === 'Admin' || rolesAccount === 'AdminPrimary'"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="6">
                      <el-form-item :label="$t('t-name-group-roles')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['name']"
                          :placeholder="$t('t-name-group-roles')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="6">
                      <el-form-item label="INOC X">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['net_xs']"
                          placeholder="INOC X"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem()"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
            <div class="mb-3" style="float: right">
              <el-pagination
                small
                v-model:currentPage="tableRules.page"
                v-model:pageSize="pageSizeDefault"
                :page-sizes="tableRules.lengthMenu"
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableRules.total"
                @size-change="fn_tableSizeChange"
                @current-change="fn_tableCurentChange"
                @prev-click="fn_tablePrevClick"
                @next-click="fn_tableNextClick"
              />
            </div>
            <el-table
              size="small"
              :data="dataTables.value"
              style="width: 100%"
              max-height="550"
              v-loading="loading"
              :empty-text="$t('t-no-data')"
              @sort-change="fn_tableSortChange"
              :default-sort="{
                prop: tableRules.defaultSort[0],
                order: tableRules.defaultSort[1],
              }"
            >
              <el-table-column prop="" :label="$t('t-stt')" width="70" align="center">
                <template #default="scope">
                  <div class="text-center">
                    {{ tableRules.offset + scope.$index + 1 }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                :label="$t('t-name-group-roles')"
                min-width="200"
                align="start"
                :sortable="true"
              />
              <el-table-column
                prop="nameProvince"
                :label="$t('t-province')"
                min-width="200"
                align="start"
              >
                <template #default="slotData">
                  <el-tooltip effect="dark" placement="top">
                    <template #content>{{
                      slotData.row.toolTipContent
                    }}</template>
                    <span>{{ slotData.row.nameProvince }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="netX"
                label="INOC X"
                min-width="200"
                align="start"
              />
              <el-table-column
                prop="roles"
                :label="$t('t-group-roles')"
                min-width="200"
                align="center"
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.roles === 'Edit'"
                    class="badge badge-soft-info text-uppercase"
                    >{{$t('t-edit')}}</span
                  >
                  <span
                    v-if="scope.row.roles === 'View'"
                    class="badge badge-soft-warning text-uppercase"
                    >{{$t('t-view')}}</span
                  >
                </template>
              </el-table-column>

              <el-table-column :label="$t('t-action')" align="center" width="150px">
                <template #default="scope">
                  <div class="btn-group-thao-tac">
                    <el-tooltip
                      :content="$t('t-edit')"
                      placement="top"
                      :enterable="false"
                      effect="light"
                    >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                      :content="$t('t-delete')"
                      placement="top"
                      :enterable="false"
                      effect="light"
                    >                  
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.$index, scope.row)"
                      :disabled="rolesAccount === 'User'"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="mt-3" style="float: right">
              <el-pagination
                small
                v-model:currentPage="tableRules.page"
                v-model:pageSize="pageSizeDefault"
                :page-sizes="tableRules.lengthMenu"
                background
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableRules.total"
                @size-change="fn_tableSizeChange"
                @current-change="fn_tableCurentChange"
                @prev-click="fn_tablePrevClick"
                @next-click="fn_tableNextClick"
              />
            </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
